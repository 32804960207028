<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>用户昵称:</span>
            <el-input
              v-model="searchOption.key"
              placeholder="请输入用户昵称"
            ></el-input>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          白名单用户（共 <span>{{ pageObj.TotalRow || 0 }}</span>个）
        </div>
      </div>
      
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="MemberNickName" label="昵称"></el-table-column>
        <el-table-column prop="GroupName" label="群名称">
            <template #default="scope">
                <div class="cell">
                    <div>{{scope.row.GroupName==''?'-':scope.row.GroupName}}</div>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="MemberIdentiyStr" label="身份"></el-table-column>
        <el-table-column prop="CreateTime" label="添加时间"></el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button type="primary" v-if="scope.row.MemberIdentiy==3" @click="removeAction(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox, ElMessage } from "element-plus";
import {GroupMemberGetWhiteList,GroupMemberRemoveRoleWhite} from "@/helper/group";
 
export default {
  name:"WhiteList",
  components: {
    TableFoot,
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);

    onMounted(() => {
      searchAction();
    });

   //移除
   function removeAction(item) {
     ElMessageBox.confirm(`确认将用户【${item.MemberNickName}】移除白名单？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          GroupMemberRemoveRoleWhite({MemberSerialNo:item.MemberSerialNo}).then(res=>{
            searchAction();
            ElMessage({
              type: 'success',
              message: '移除成功!',
            });
          }) 
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
          });
   }
   //转化身份
   function changeStatus(num) {
      if(num==1){
        return "管理员"
      } else if(num==2){
        return "群主"
      } else if(num==3){
        return "白名单"
      } 
   }
    /**********************搜索***************************/
    const searchOption = reactive({
      key: "",
    });

    //执行搜索
    function searchAction() {
        let param={
            Name:searchOption.key || "",
            PageNum:pageObj.PageIndex,
            PageSize:pageObj.PageSize,
        }
        GroupMemberGetWhiteList(param).then((res) => {
            console.log(res);
            pageObj.TotalRow=res.TotalCount
            tableData.value=res.List
            res.List.forEach(item => {
              console.log(item);
                item.MemberIdentiyStr=changeStatus(item.MemberIdentiy)
            });
        })
    }

    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchAction();
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    
    return {
      tableData,
      searchOption,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      removeAction
    };
  },
};
</script>

<style lang="scss" scoped>

</style>